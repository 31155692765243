import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

// Featured Slider
var feature_slider = new swiper('.feature-slider-container', {
    direction: 'horizontal',
    autoHeight: true,
    loop: false,
    //parallax: true,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.feature-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Content Slider
  var wine_content_slider = new swiper('.swiper-container', {
    direction: 'horizontal',
    autoHeight: false,
    loop: true,
    watchOverflow: true,
    effect: 'slide',
    // slidesPerView: 5,
    // spaceBetween: 10,
    breakpoints: {
      '@0.00': {
        slidesPerView: 3,
        spaceBetween: 55
      },
      '@0.50': {
        slidesPerView: 3,
        spaceBetween: 55
      },
      '@0.70': {
        slidesPerView: 3,
        spaceBetween: -15
      },
      '@0.80': {
        slidesPerView: 3,
        spaceBetween: -15
      },
      '@0.85': {
        slidesPerView: 3,
        spaceBetween: -20
      },
      '@0.90': {
        slidesPerView: 3,
        spaceBetween: -30
      },
      '@1.00': {
        slidesPerView: 5,
        spaceBetween: -20
      },
      '@1.25': {
        slidesPerView: 5,
        spaceBetween: -60
      },
      '@1.50': {
        slidesPerView: 7,
        spaceBetween: -60
      }
    },
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
});
// Content Slider
// var wine_content_slider = new swiper('.wine-singles', {
//   direction: 'horizontal',
//   autoHeight: true,
//   loop: true,
//   watchOverflow: true,
//   effect: 'slide',
//   centeredSlides: true,
//   //centeredSlidesBounds: true,
//   //centerInsufficientSlides: true,
//   // slidesPerView: 5,
//   // spaceBetween: 10,
//   breakpoints: {
//     '@0.00': {
//       slidesPerView: 1,
//       spaceBetween: 20
//     },
//     '@0.50': {
//       slidesPerView: 1,
//       spaceBetween: 20
//     },
//     '@0.70': {
//       slidesPerView: 3,
//       spaceBetween: -15
//     },
//     '@0.80': {
//       slidesPerView: 3,
//       spaceBetween: -15
//     },
//     '@0.85': {
//       slidesPerView: 3,
//       spaceBetween: -20
//     },
//     '@0.90': {
//       slidesPerView: 3,
//       spaceBetween: -30
//     },
//     '@1.00': {
//       slidesPerView: 5,
//       spaceBetween: -60
//     },
//     '@1.25': {
//       slidesPerView: 5,
//       spaceBetween: -60
//     },
//     '@1.50': {
//       slidesPerView: 7,
//       spaceBetween: -60
//     }
//   },
//   navigation: {
//     nextEl: '.swiper-next',
//     prevEl: '.swiper-prev',
//   },
// });