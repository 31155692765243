import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

const boxes = gsap.utils.toArray('.anim');
gsap.set(boxes, {autoAlpha: 0, y: 250});

boxes.forEach((box, i) => {
  const anim = gsap.to(box, {duration: 1, autoAlpha: 1, y: 0, paused: true});
  
ScrollTrigger.create({
    trigger: box,
    end: "top top",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

const animRotate = document.querySelectorAll('.animRotate')

  animRotate.forEach((obj, index) => {
      let tl = gsap.timeline({
          // yes, we can add it to an entire timeline!
          scrollTrigger: {
              trigger: obj,
              start: 'top-=700px top',
              end: '+=700px',
              scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          }
      })
      tl.fromTo(obj,
          {rotate: '-60deg', duration: 1},
          {rotate: '0deg'})
})